/* @flow */

import * as React from 'react'
import { DownloadThankTemplate } from '../templates'
import type { Location } from 'react-router'
import { Redirect } from '@reach/router'

type Props = {
  location: Location,
}

export default function downloadThank(props: Props) {
  const { location } = props
  if (!location.state) return <Redirect noThrow to="/" />

  const { dlUrl, formName, suggestions } = location.state

  return (
    <DownloadThankTemplate
      url={location.href}
      name="Wino"
      title="Merci du telechargement !"
      dlUrl={dlUrl}
      formName={formName}
      suggestions={suggestions}
    />
  )
}
